'use client';

import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type BannerProps = {
  handleCloseBanner: () => void;
  isBannerOpen: boolean;
};

function Banner({ handleCloseBanner, isBannerOpen }: BannerProps) {
  return (
    <div
      className={clsx(
        'bg-custom-gradient overflow-hidden transition-all duration-500',
        isBannerOpen ? 'max-h-[--navbarBannerMobileHeight]' : 'max-h-0',
      )}
      data-testid="announcement-banner"
      data-banner-open={isBannerOpen}
    >
      <div className="max-w-section flex h-[--navbarBannerMobileHeight] items-center justify-center px-3 py-[0.625rem] text-center text-body-md text-white md:h-[--navbarBannerHeight]">
        <p className="w-full leading-[18px]">
          Join us on Feb-27 for the “Observing build and CI productivity in your favorite OSS projects” webinar!{' '}
          <a href="/events/observing-build-ci-productivity-oss-projects/" className="mega-menu-link underline">
            Register
          </a>
        </p>
        <button
          onClick={handleCloseBanner}
          className="cursor-pointer rounded-full p-1 transition-colors hover:bg-gradle-blue"
        >
          <XMarkIcon className="size-5 transition-transform md:size-4" />
        </button>
      </div>
    </div>
  );
}

export default Banner;
